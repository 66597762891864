// Store singletons for easy access
export default {
	GUI: null,
	Tools: null,
	Layers: null,
	Config: null,
	State: null,
	FileOpen: null,
	FileSave: null,
	Actions: null
};