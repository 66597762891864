/** 
 * manages files / print
 * 
 * @author ViliusL
 */
class File_print_class {

	print() {
		window.print();
	}

}

export default File_print_class;